import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, Link } from "@reach/router";
import { Spin, Typography, Icon, Form, Input, Button, notification, Modal } from 'antd';
import { AuthStoreContext } from './stores/Contexts';
import Config from './Config';

import { withSimpleLayout } from './Layouts';
import { getAbsoluteURL } from './common';

const PasswordExpired = withSimpleLayout( observer((props) => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const authStore = useContext(AuthStoreContext);

	function onSubmit() {
		setSubmitting(true);
		authStore.requestPasswordReset({ email: authStore.email }).then( () => {
			notification.info({
				message: 'Reset link requested'
				,description: 'If your email is in our database, you\'ll receive a mail containing the reset link shortly'
				,duration: 8
			});
			//wait 90 secs before re-enabling the button
			setTimeout( () => setIsSubmitted(true), 90000);
		}).catch( (err) => {
			const responseDataErr = err && err.response && err.response.data && err.response.data.error || null;
			if (responseDataErr && responseDataErr.code == 'RESET_FAILED_EMAIL_NOT_VERIFIED') {
				Modal.confirm({
					title: 'Mail not verified',
					content: (<div>
						Before resetting your password you have to confirm your email. We'll send a verification link to your email address and after the procedure is completed you'll be able to reset your password as normal.<br/>
						Do you want to proceed?
					</div>),
					okText: 'Yes, send me the verification link',
					cancelText: 'No',
					onOk() {
						console.log('Yes');
						authStore.verify({ id: responseDataErr.details && responseDataErr.details.userId || null }).catch( err => {
							notification.error({
								message: 'Cannot send verification mail'
								,description: 'Please try again or contact the Administrator'
								,duration: 5
							});
						});
					},
					onCancel() {
						console.log('No');
					},
				});
				return;
			}
			notification.error({
				message: 'Error'
				,description: 'Cannot send the reset link. Please try again'
				,duration: 8
			});
		}).then( () => {
			setSubmitting(false);
		});
	}

	return (<div className="password-expired-container page-form-container">
		<div className='title-container'>
			<Typography.Title level={2} className="text-center">BigEye Password Expired</Typography.Title>
		</div>
		<p>BigEye requires a password change every 90 days since your registration date.</p>
		<p>
		You might already have a recent password reset email in your inbox, otherwise you can request a new one by clicking the following button.
		</p>
		<p>Email: {authStore.email}</p>
		<Form.Item>
			<Button type="primary" disabled={submitting} onClick={onSubmit} block>Request a reset link</Button>
		</Form.Item>
	</div>);
}), { hasBg: true } );

export default PasswordExpired;
