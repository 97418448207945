import React, { useContext, useState, useEffect } from 'react';
import { Redirect, Link } from "@reach/router";
import { Spin, Typography, Icon, Form, Input, Button, notification, Modal } from 'antd';
import { AuthStoreContext } from './stores/Contexts';
import Config from './Config';

import { withSimpleLayout } from './Layouts';
import { getAbsoluteURL } from './common';

const PasswordForgotten = withSimpleLayout( (props) => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const authStore = useContext(AuthStoreContext);

	function onSubmit(values) {
		setSubmitting(true);
		authStore.requestPasswordReset(values).then( () => {
			notification.info({
				message: 'Reset link requested'
				,description: 'If your email is in our database, you\'ll receive a mail containing the reset link shortly'
				,duration: 8
			});
			setIsSubmitted(true);
		}).catch( (err) => {
			const responseDataErr = err && err.response && err.response.data && err.response.data.error || null;
			if (responseDataErr && responseDataErr.code == 'RESET_FAILED_EMAIL_NOT_VERIFIED') {
				Modal.confirm({
					title: 'Mail not verified',
					content: (<div>
						Before resetting your password you have to confirm your email. We'll send a verification link to your email address and after the procedure is completed you'll be able to reset your password as normal.<br/>
						Do you want to proceed?
					</div>),
					okText: 'Yes, send me the verification link',
					cancelText: 'No',
					onOk() {
						console.log('Yes');
						authStore.verify({ id: responseDataErr.details && responseDataErr.details.userId || null }).catch( err => {
							notification.error({
								message: 'Cannot send verification mail'
								,description: 'Please try again or contact the Administrator'
								,duration: 5
							});
						});
					},
					onCancel() {
						console.log('No');
					},
				});
				return;
			}
			notification.error({
				message: 'Error'
				,description: 'Cannot send the reset link. Please try again'
				,duration: 8
			});
		}).then( () => {
			setSubmitting(false);
		});
	}

	if (isSubmitted){
		return <Redirect to={getAbsoluteURL('/login')} noThrow />
	}

	return (<div className="password-forgotten-form-container page-form-container">
		<div className='title-container'>
			<Typography.Title level={2} className="text-center">BigEye Password Forgotten</Typography.Title>
		</div>
		<PasswordForgottenForm store={authStore} onSubmit={onSubmit} submitting={submitting} />
	</div>);
}, { hasBg: true } );

const PasswordForgottenForm = Form.create({
        name: 'password-forgotten-form'
})( (props) => {
        const { getFieldDecorator } = props.form;
	function handleSubmit(e) {
		props.form.validateFields((err, values) => {
			e.preventDefault();
			if (err) {
				return;
			}
			props.onSubmit(values);
		});
	}

	return (
		<Form className="password-forgotten-form" layout='vertical' onSubmit={handleSubmit}>
			<Form.Item label='Email'>
			{ getFieldDecorator('email', {
				rules: [ {type: 'email', message: 'The input is not valid E-mail!' }
					 ,{ required: true, message: 'Please insert your email' }
				]
			})( <Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} type="text" placeholder="email" />) }
			</Form.Item>
			<Form.Item>
				<Link to={getAbsoluteURL('/login')}>Back to Login</Link>
			</Form.Item>
			<Form.Item>
				<Button className="submit" type="primary" htmlType="submit" disabled={props.submitting} block>Request reset link</Button>
			</Form.Item>
		</Form>
	);
} );

export default PasswordForgotten;
