import React, { useContext, useState, useEffect } from 'react';
import { Redirect, Link } from "@reach/router";
import { Spin, Typography, Icon, Form, Input, Button, notification } from 'antd';
import { AuthStoreContext } from './stores/Contexts';
import Config from './Config';

import { withSimpleLayout } from './Layouts';
import { getAbsoluteURL, validatePassword } from './common';

const PasswordReset = withSimpleLayout( (props) => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const authStore = useContext(AuthStoreContext);

	const isInvite = props.location.hash.indexOf('invite') == 1;

	useEffect( () => {
		if (!props.token) {
			notification.error({
				message: 'Error'
				,description: 'Wrong password reset link: missing url token'
				,duration: 8
			});
		}
	}, []);

	function onSubmit(values) {
		setSubmitting(true);
		authStore.resetPassword({ ...values, token: props.token }).then( () => {
			notification.success({
				message: 'Success'
				,description: isInvite ?
					'The password has been set successfully. You can log in now'
					: 'The password has been reset successfully. You can log in now'
				,duration: 8
			});
			setIsSubmitted(true);
		}).catch( (err) => {
			notification.error({
				message: 'Error'
				,description: 'Cannot reset the password. Please try again'
				,duration: 8
			});
		}).then( () => {
			setSubmitting(false);
		});
	}

	if (isSubmitted || !props.token){
		if (authStore._passwordExpired) { //if password is expired and we changed it, we should reload...
			window.location.pathname = getAbsoluteURL('/login');
			return null;
		}
		return <Redirect to={getAbsoluteURL('/login')} noThrow />
	}

	return (<div className="password-reset-form-container page-form-container">
		<div className='title-container'>
			<Typography.Title level={2} className="text-center">{isInvite ? 'BigEye Password Setup' : 'BigEye Password Reset'}</Typography.Title>
		</div>
		<PasswordResetForm store={authStore} onSubmit={onSubmit} submitting={submitting} isInvite={isInvite} />
	</div>);
}, { hasBg: true } );

const PasswordResetForm = Form.create({
        name: 'password-reset-form'
})( (props) => {
        const { getFieldDecorator, getFieldValue } = props.form;

	function handleSubmit(e) {
		props.form.validateFields((err, values) => {
			e.preventDefault();
			if (err) {
				return;
			}
			props.onSubmit(values);
		});
	}

	function validatePasswordConfirmation(rule, value, callback){
		if (value != getFieldValue('password')) {
			return callback('The passwords must be the same!');
		}
		callback();
	}

	return (
		<Form className="password-reset-form" layout='vertical' onSubmit={handleSubmit}>
			<Form.Item label='Password'>
			{ getFieldDecorator('password', {
				rules: [ { required: true, message: 'Please insert the new password' }, { validator: validatePassword }
				]
			})( <Input type="password" prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="password" />) }
			</Form.Item>
			<Form.Item label='Retype password'>
			{ getFieldDecorator('password-confirm', {
				rules: [ { required: true, message: 'Please insert the password confirmation' }
					 ,{ validator: validatePasswordConfirmation }
				]
			})( <Input type="password" prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="password" />) }
			</Form.Item>
			<Form.Item>
				<Link to={getAbsoluteURL('/login')}>Back to Login</Link>
			</Form.Item>
			<Form.Item>
				<Button className="submit" type="primary" htmlType="submit" disabled={props.submitting} block>
				{props.isInvite ? 'Setup password' : 'Reset password' }
				</Button>
			</Form.Item>
		</Form>
	);
} );

export default PasswordReset;
