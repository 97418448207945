import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, Link } from "@reach/router";
import { Spin, Typography, Icon, Form, Input, Button, notification, Modal } from 'antd';
import { AuthStoreContext } from './stores/Contexts';
import Config from './Config';

import { withSimpleLayout } from './Layouts';
import { getAbsoluteURL } from './common';

const Login = withSimpleLayout( observer( (props) => {
	const authStore = useContext(AuthStoreContext);
	const redirectTo = props.location.state && props.location.state.from ? props.location.state.from : Config.MOUNT_POINT;

	if (authStore.isAuthenticated === null){
		return (<div>
			Checking authentication... <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
		</div>);
	}
	else if (authStore.isAuthenticated === false){
		return (<div className="login-form-container page-form-container">
			<div className='title-container'>
				<Typography.Title level={2} className="text-center">BigEye Login</Typography.Title>
			</div>
			<LoginForm store={authStore} />
		</div>);
	}
	//if we're here, we're logged in
	return <Redirect to={redirectTo} noThrow />
}), { hasBg: true } );

const LoginForm = Form.create({
        name: 'login-form'
})(observer( (props) => {
        const { getFieldDecorator } = props.form;
	function handleSubmit(e) {
		props.form.validateFields((err, values) => {
			e.preventDefault();
			if (err) {
				return;
			}
			props.store.login(values).catch( (err) => {
				console.log(err.response);
				const responseDataErr = err && err.response && err.response.data && err.response.data.error || null;
				if (responseDataErr && responseDataErr.code == 'LOGIN_FAILED_EMAIL_NOT_VERIFIED') {
					Modal.confirm({
						title: 'Mail not verified',
						content: (<div>
							Before logging in you have to confirm your email. Please check if you have a confirmation link in your inbox.<br/>
							You can also request a new verification email now. Do you want to proceed?
						</div>),
						okText: 'Yes, send me a new link',
						cancelText: 'No',
						onOk() {
							console.log('Yes');
							props.store.verify({ id: responseDataErr.details && responseDataErr.details.userId || null }).catch( err => {
								notification.error({
									message: 'Cannot send verification mail'
									,description: 'Please try again or contact the Administrator'
									,duration: 5
								});
							});
						},
						onCancel() {
							console.log('No');
						},
					});
					return;
				}
				notification.error({
					message: 'Cannot log in'
					,description: 'Please check your email/password pair'
					,duration: 5
				});
			});
		});
	}

	return (
		<Form className="login-form" layout='vertical' onSubmit={handleSubmit}>
			<Form.Item label='Email'>
			{ getFieldDecorator('email', {
				rules: [ { required: true, message: 'Insert your email' }
				]
			})( <Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} type="text" placeholder="email/username" name="username" />) }
			</Form.Item>
			<Form.Item label='Password'>
			{ getFieldDecorator('password', {
				rules: [ { required: true, message: 'Insert your password' } ]
			})( <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="password" name="password" />) }
			</Form.Item>
			<Form.Item>
				<Link to={getAbsoluteURL('/password-forgotten')}>Password Forgotten</Link>
			</Form.Item>
			<Form.Item>
				<Button className="submit" type="primary" htmlType="submit" disabled={props.store.loggingIn} block>Log In</Button>
			</Form.Item>
		</Form>
	);
}));

export default Login;
